import Sugar from "sugar"
import qs from "qs"
import {
  normalizePhone,
  parsePhone,
  formatZipCode,
  normalizeCreditCardExpiration,
  formatSSN,
  parseSSN,
  parseNumber,
  parseNumericVerificationCode,
  parseBirthDate,
  formatDate,
  formatDateAsMonthDateYear,
  normalizeDateToYearMonthDay,
  normalizeDateToMonthDayYear,
  formatMonthDateYear,
  humanizeDate,
  formatCurrency,
  formatCurrencyCents,
  parseCurrency,
  parseCurrencyCents,
  formatCurrencyWithCents,
  formatCurrencyNoZeroCents,
  formatCurrencyWithOrWithoutCents,
  formatUppercase,
} from "./parse-format"
import { parseCountry } from "./parse-country"
import { businessDaysFromDate, formatDateTime } from "./dateTime"
import getCookie from "./getCookie"
import setCookie, { deleteCookie } from "./setCookie"
import states, { unitedStates, getStateNameFromAbbreviation } from "./states"
import { countries, countriesReordered } from "./countries"
import creditCardType from "./creditCardType"
import DOMUtils from "./DOMUtils"
import propertyTypes from "./propertyTypes"
import trackingAssist from "./trackingAssist"
import useDimensions from "./useDimensions"
import { useRemToPx, usePxToRem } from "./theme"
import { formatResponse, makeRequest } from "./requestHelpers"
import setHoneyBadgerUser from "./setHoneyBadgerUser"
import { facebookShareUrl } from "./share"

require("sugar-inflections")

Sugar.extend()

const noop = () => {}

const MAX_ALLOWED_IMAGE_SIZE = 10000000 // 10 Mb
const MAX_ALLOWED_DOCUMENT_SIZE = 20000000 // 20 Mb

const MAX_ALLOWED_IMAGE_UPLOAD_COUNT = 20

const acceptedImageList = ["image/jpeg", "image/jpg", "image/png", "image/gif"]
const acceptedDocumentList = ["application/pdf", "application/msword", "text/plain"]

const acceptedImageTypes = acceptedImageList.join(",")
const acceptedDocumentTypes = [...acceptedImageList, ...acceptedDocumentList].join(",")

const hasValue = (obj) => !!obj

const humanizeFileSize = (size) => {
  if (size <= 1000000) {
    return `${Math.round(size / 1000)}KB`
  }
  return `${(size / 1000000).toFixed(1)}MB`
}

function noFilesExceedMaxAllowedFileSize(files) {
  if (files.length > 0) {
    for (let i = 0; i < files.length; i += 1) {
      if (files[i].size > MAX_ALLOWED_DOCUMENT_SIZE) {
        const fileSize = humanizeFileSize(MAX_ALLOWED_DOCUMENT_SIZE)
        const message = `Please select files less than ${fileSize} each`
        alert(message)
        return false
      }
    }
  }
  return true
}

const doesNotExceedMaxAllowedFileCount = (files) => {
  const fileAttributes = {
    fileCount: files.length,
  }

  if (files.length > MAX_ALLOWED_IMAGE_UPLOAD_COUNT) {
    fileAttributes.error = `Please select fewer than ${MAX_ALLOWED_IMAGE_UPLOAD_COUNT} images at a time`
  }
  return fileAttributes
}

const filterFileListForAllowedFiles = (files = []) => {
  if (files.length > 0) {
    return files.map((file) => {
      const { name, size, type } = file
      const fileAttributes = {
        name,
        size: humanizeFileSize(size),
      }
      if (!acceptedImageList.includes(type)) {
        fileAttributes.error = "File type is not supported."
      } else if (size > MAX_ALLOWED_IMAGE_SIZE) {
        fileAttributes.error = `File is too large (max ${humanizeFileSize(
          MAX_ALLOWED_IMAGE_SIZE,
        )}).`
      } else {
        fileAttributes.file = file
      }
      return fileAttributes
    })
  }
}

function without() {
  const obj = {}

  const { indexOf } = Array.prototype
  for (const i in this) {
    if (arguments::indexOf(i) >= 0) {
      continue
    }
    if (!Object.prototype.hasOwnProperty.call(this, i)) {
      continue
    }
    obj[i] = this[i]
  }
  return obj
}

function pick() {
  const obj = {}
  for (let i = 0; i < arguments.length; i++) {
    if (this.hasOwnProperty([arguments[i]])) {
      obj[arguments[i]] = this[arguments[i]]
    }
  }
  return obj
}

function select(fn) {
  return this.filter(fn)
}

function where(obj) {
  return this.filter((item) => {
    for (const key in obj) {
      if (item.hasOwnProperty(key)) {
        const test = obj[key]
        if (Array.isArray(test) && test.indexOf(item[key]) > -1) {
          continue
        } else if (item[key] === test) {
          continue
        }
      }
      return false
    }
    return true
  })
}
where.not = function (obj) {
  return this.filter((item) => {
    for (const key in obj) {
      if (item.hasOwnProperty(key)) {
        const test = obj[key]
        if (Array.isArray(test) && test.indexOf(item[key]) === -1) {
          continue
        } else if (item[key] !== test) {
          continue
        }
      }
      return false
    }
    return true
  })
}

function isEmptyObject() {
  for (const name in this) {
    return false
  }
  return true
}

function objToQueryString(obj) {
  return qs.stringify(obj, { addQueryPrefix: true })
}

function queryStringToObj(str) {
  return qs.parse(str, {
    ignoreQueryPrefix: true,
    decoder: (value, defaultDecoder) => {
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value)
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      }

      if (value in keywords) {
        return keywords[value]
      }

      return defaultDecoder(value)
    },
  })
}

function scrollTo(element, duration, offset = 0) {
  let start

  const easing = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1)

  const elementPosition = element.getBoundingClientRect().top - offset

  const currentPosition = window.pageYOffset

  const targetPosition = elementPosition + currentPosition

  const scrollDiff = targetPosition - currentPosition

  if (!scrollDiff) {
    return
  }

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) {
      start = timestamp
    }
    const time = timestamp - start

    let percent = Math.min(time / duration, 1)
    percent = easing(percent)
    window.scrollTo(0, currentPosition + scrollDiff * percent)
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

function toInitials() {
  return this.trim()
    .split(/\s+/)
    .map((n) => n[0].toUpperCase())
    .join("")
}
if (!("toInitials" in String.prototype)) {
  Object.defineProperty(String.prototype, "toInitials", {
    value: toInitials,
  })
}

;(function () {
  if (!window.location.origin) {
    window.location.origin = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }`
  }
})()

/** ************************************** */
/** ********** Number Functions ********** */
/** ************************************** */
// "To Human File Size"
// "To Card Format"
// To Money
const toMoney = function (opts) {
  const options = opts || {}

  let n = this

  const c = 2

  const d = "."

  const t = ","

  const s = n < 0 ? (options.signSpacer ? "$ -" : "-$") : options.signSpacer ? "$ " : "$"

  const i = `${parseInt((n = Math.abs(+n || 0).toFixed(c)))}`

  var j = (j = i.length) > 3 ? j % 3 : 0

  const formattedString =
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  return options.noCents ? formattedString.replace(".00", "") : formattedString
}
Number.prototype.toMoney = toMoney

function parseFloatWithoutNaNValues(num) {
  return isNaN(parseFloat(num)) ? null : parseFloat(num.replace(",", ""))
}

/** ************************************** */
/** ********** Array Functions *********** */
/** ************************************** */
function sortOn(key, descending) {
  return this.slice().sort((a, b) => {
    if (a[key] < b[key]) {
      return descending ? 1 : -1
    }
    if (a[key] > b[key]) {
      return descending ? -1 : 1
    }
    return 0
  })
}
Object.defineProperty(Array.prototype, "sortOn", {
  value: sortOn,
})

function sort(...args) {
  return this.slice().sort(...args)
}

// Unique Arrays
// Limitation: objects must be in the same order
// Object.defineProperty(Array.prototype, "unique", {
//   value: function() {
//     var n = {},r=[];
//     for(var i = 0; i < this.length; i++) {
//       if (!n[JSON.stringify(this[i])]) {
//         n[JSON.stringify(this[i])] = true;
//         r.push(this[i]);
//       }
//     }
//     return r;
//   }
// })

// Descending (reverse if true)
Object.defineProperty(Array.prototype, "descending", {
  value(descending) {
    return descending ? this.slice().reverse() : this
  },
})

// Chunk
Object.defineProperty(Array.prototype, "chunk", {
  value(n) {
    if (!this.length) {
      return []
    }
    return [this.slice(0, n)].concat(this.slice(n).chunk(n))
  },
})

// To className
Object.defineProperty(Array.prototype, "toClassName", {
  value() {
    return this.filter(Boolean).join(" ")
  },
})

/** ************************************** */
/** ********* String Functions *********** */
/** ************************************** */
// Is plural?
Object.defineProperty(String.prototype, "isPlural", {
  value() {
    return this === this.pluralize()
  },
})

// To Human File Size
const toHumanFileSize = function (si) {
  if (isNaN(parseInt(this))) {
    return ""
  }

  const thresh = si ? 1000 : 1024
  let bytes = parseInt(this)
  if (Math.abs(bytes) < thresh) {
    return `${bytes} bytes`
  }
  const units = si
    ? ["kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"]
    : ["kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return `${bytes.toFixed(1)} ${units[u]}`
}
String.prototype.toHumanFileSize = toHumanFileSize
Number.prototype.toHumanFileSize = toHumanFileSize

// To Card Format
const toFormattedCardNumber = function () {
  return this
  // Remove all spaces and non-numbers
  // let v = this.toString().replace(/\s+/g, ""),
  //   // Match only 4-16 digits
  //   matches = v.match(/.{4,}/g),
  //   // And assign it to a variable
  //   match = (matches && matches[0]) || "",
  //   parts = []
  // // Loop through every character in the match, incrementing by 4 characters each time
  // for (let i = 0, len = match.length; i < len; i += 4) {
  //   // Group every 4 numbers
  //   parts.push(match.substring(i, i + 4))
  // }
  // // Add a space to the end to prompt for more information
  // // if (parts.length && parts.length < 4) parts.push("")
  // if (parts.length) {
  //   return parts.join(" ")
  // }
  // return this.toString()
}
String.prototype.toFormattedCardNumber = toFormattedCardNumber
Number.prototype.toFormattedCardNumber = toFormattedCardNumber

const toCardType = function (fallback) {
  const cardNumber = this.toString().replace(/\s+/g, "")
  switch (true) {
    case /^4[0-9]{6,}$/.test(cardNumber):
      return "Visa"
    case /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
      cardNumber,
    ):
      return "MasterCard"
    case /^(34|37)[0-9]*/.test(cardNumber):
      return "American Express"
    case /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(cardNumber):
      return "Diners Club"
    case /^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(cardNumber):
      return "Discover"
    case /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/.test(cardNumber):
      return "JCB"
    default:
      return fallback || ""
  }
}
String.prototype.toCardType = toCardType
Number.prototype.toCardType = toCardType

// Hashcode
String.prototype.hashCode = function () {
  let hash = 0

  let i

  let chr
  if (this.length === 0) {
    return hash
  }
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

String.uuid = function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + s4()
}

// Titlecase
String.prototype.titlecase = function () {
  return this.toString()
    .toLowerCase()
    .replace(/[_-]/g, " ")
    .trim()
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
}

// Humanize
String.prototype.humanize = function () {
  return this.toString()
    .toLowerCase()
    .replace(/[_-]/g, " ")
    .trim()
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
      }
      return word
    })
    .join(" ")
}

// To Phone
String.prototype.toPhone = function () {
  return this.replace(
    /^[\+\d{1,3}\-\s]*\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})(.*)$/,
    "($1) $2-$3 $4",
  ).trim()
}

// To Money
String.prototype.toMoney = toMoney

// Trim Specific Character from beginning and end of string
String.prototype.trimChar = function (charToRemove) {
  let string
  if (this.charAt(0) == charToRemove) {
    string = this.substring(1)
  }
  if (this.charAt(this.length - 1) == charToRemove) {
    string = this.substring(0, this.length - 1)
  }
  return string
}

// Regex IndexOf
String.prototype.regexIndexOf = function (regex, startpos) {
  const indexOf = this.substring(startpos || 0).search(regex)
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf
}

function camelCase(string) {
  const newString = string.replace(/(\_\w)/g, (c) => c[1].toUpperCase())
  return newString
}

function toFirstName() {
  return this.split(" ")[0]
}

function toFirstNames() {
  return this.map((str) => str::toFirstName())
}

function toSentence() {
  const arr = this.filter(Boolean)
  switch (arr.length) {
    case 0:
      return ""
    case 1:
      return arr[0]
    default:
      return `${arr.slice(0, -1).join(", ")} and ${arr.slice(-1)[0]}`
  }
}

function endsWith(searchStr, Position) {
  if (!(Position < this.length)) {
    Position = this.length
  } else {
    Position |= 0 // round position
  }
  return this.substr(Position - searchStr.length, searchStr.length) === searchStr
}

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

// Determine current env
const development = process.env.AVAIL_ENV === "development"
const staging = process.env.AVAIL_ENV === "staging"
const production = process.env.AVAIL_ENV === "production"

const fluteMatcher = /^@FLUTE_(SET|GET|POST|PUT|DELETE|REQUEST_INFO|SAVE)(_SUCCESS)?_(.*)$/

const reactiveRecordMatcher = /^@(OK_|ERROR_)?(INDEX|CREATE|SHOW|UPDATE|DESTROY)\(([^)]+)\)$/

function spaceBetweenEach() {
  if (this.length < 2) {
    return this
  }
  return this.slice(1).reduce((prev, curr) => [...prev, " ", curr], [this[0]])
}

function triggerEventForProps(type, e) {
  const fn = this.props[`on${type}`]
  if (typeof fn === "function") {
    fn.call(this, e)
  }
}

function splice(start, deleteCount, ...items) {
  return [...this.slice(0, start), ...items, ...this.slice(start + deleteCount)]
}

function resultAndRemainder(filter) {
  const result = filter.call(this, this)
  const remainder = this.filter((i) => result.indexOf(i) < 0)
  return [result, remainder]
}

/*
 *** API REQUESTS
 */

function getScript(scriptsrc) {
  return new Promise((resolve, reject) => {
    if (typeof window === undefined) {
      return
    }
    const script = document.createElement("script")
    script.onreadystatechange = function () {
      if (script.readyState === "complete" || script.readyState === "loaded") {
        script.onreadystatechange = null
        resolve()
      }
    }
    script.src = scriptsrc

    const head = document.getElementsByTagName("head")[0]
    head.insertBefore(script, head.firstChild)
  })
}

function grabAllValues(obj, values = []) {
  return Object.values(obj).reduce((final, val) => {
    if (typeof val === "object") {
      return grabAllValues(val, final)
    }
    values.push(val)
    return values
  }, values)
}

function timezoneName() {
  const dateObject = new Date()

  const dateString = `${dateObject}`

  let tzAbbr = dateString.match(/\(([^\)]+)\)$/) || dateString.match(/([A-Z]+) [\d]{4}$/)
  if (tzAbbr) {
    tzAbbr = tzAbbr[1]
  }
  return tzAbbr
}

function toRGB(a) {
  const hex = this
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (a) {
    return `rgba(${[r, g, b, a].join(",")})`
  }
  return `rgb(${[r, g, b].join(",")})`
}

function parseErrorMessages(errors, withExtraErrorMessage = true) {
  const errorMessages = []
  Object.keys(errors).map((attr) => {
    if (attr && errors[attr] && errors[attr].length) {
      errorMessages.push(`${errors[attr].join(". ")}`)
    }
    return errorMessages
  })
  if (errorMessages.length) {
    return withExtraErrorMessage
      ? `An error occurred processing your request: ${errorMessages.join(". ")}`
      : errorMessages.join(" ")
  }
  return "An unknown error occurred."
}

function parseOperationErrors(errors) {
  const messages = errors.reduce((accum, curr) => {
    accum.push(curr.message)
    return accum
  }, [])
  if (messages.length !== 0) {
    return messages.join(". ")
  }

  return "An unknown error occurred."
}

function selectErrors(graphQLErrors, interestedErrorTypes = ["UNAUTHORIZED", "UNEXPECTED"]) {
  const authorization_errors = graphQLErrors.filter((error) => {
    if (error.extensions && error.extensions.code) {
      return interestedErrorTypes.includes(error.extensions.code)
    }
    return false
  })
  if (authorization_errors.length === 0) {
    return null
  }
  return parseOperationErrors(authorization_errors)
}

// This is used if we want the default to be true if the current value is null
// If the current value is not null, then return the actual value
function determineDefaultChecked(value) {
  return value === null || value
}

function disableEnterSubmission(e) {
  if (e.key === "Enter") {
    e.preventDefault()
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const getMobileOperatingSystem = () => {
  // https://stackoverflow.com/a/51755182
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone"
  }

  if (/android/i.test(userAgent)) {
    return "Android"
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  }

  return "unknown"
}

const isDateSupported = () => {
  // https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/
  const input = document.createElement("input")
  const value = "a"
  input.setAttribute("type", "date")
  input.setAttribute("value", value)
  return input.value !== value
}

const checkIfDuplicateExists = (w) => new Set(w).size !== w.length

const isAdmin = (user) =>
  user.ghost_level === "admin" && user.ghost_uuid && getCookie("uuid") === user.ghost_uuid

const maskedPhone = (phone) => `(***) *** **${phone.last(2)}`

const checkFormDataForEmptyStrings = (initialValues, formValues) => {
  /*
    5/27/20 Implementation Note:
    React Final Form has an outstanding issue in which a field is going from some content to an empty string, it will not include
    that value in the submission values object. In order to make sure the empty string is submitted, we have to parse the values
    in the submission values object against the initialValues object. Any keys that are undefined are assigned to an empty string value.
    https://github.com/final-form/react-final-form/issues/130
  */
  const data = Object.keys(initialValues).reduce((acc, key) => {
    acc[key] = typeof formValues[key] === "undefined" ? "" : formValues[key]
    return acc
  }, {})
  return data
}

const focusOnElement = (id = "") => {
  if (!document) {
    return null
  }
  const element = document.getElementById(id)
  if (element) {
    element.focus()
  }
}

/** Converts array style of errors typical for ReactiveRecord to a simple key-value hash compatible
 * with FinalForm */
const toFinalFormErrors = (_errors) =>
  Object.keys(_errors).reduce((res, key) => {
    if (_errors[key] && _errors[key].length) {
      return { ...res, [key]: _errors[key][0] }
    }
    return res
  }, {})

const parseGooglePlace = (place = {}) => {
  const components = (place.address_components || []).reduce((acc, data) => {
    data.types.forEach((type) => {
      acc[type] = data
    })
    return acc
  }, {})

  const getComponent = (key, short) => {
    if (!(key in components)) {
      return ""
    }

    return short ? components[key].short_name : components[key].long_name
  }

  const result = {
    streetNumber: getComponent("street_number"),
    streetName: getComponent("route"),
    city:
      getComponent("locality") ||
      getComponent("sublocality") ||
      getComponent("sublocality_level_1") ||
      getComponent("neighborhood") ||
      getComponent("administrative_area_level_3") ||
      getComponent("administrative_area_level_2"),
    county: getComponent("administrative_area_level_2"),
    state: getComponent("administrative_area_level_1"),
    stateShort: getComponent("administrative_area_level_1", true),
    country: getComponent("country"),
    countryShort: getComponent("country", true),
    zip: getComponent("postal_code"),
  }

  result.address1 = [result.streetNumber, result.streetName].filter(Boolean).join(" ")

  return result
}

const getTimeRemaining = (date) => {
  const duration = (new Date(date) - new Date()).duration()
  if (duration !== "0 milliseconds") {
    return duration
  }
  return null
}

const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_^][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""))

const s3AssetUrl = (objectPath) => `${process.env.S3?.S3_ASSET_URL}/${objectPath}`
const s3EmailUrl = (objectPath) => `${process.env.S3?.S3_EMAIL_ASSET_URL}/${objectPath}`
const s3MediaKitUrl = (objectPath) => `${process.env.S3?.S3_MEDIA_KIT_URL}/${objectPath}`
const s3MiscUrl = (objectPath) => `${process.env.S3?.S3_MISC_URL}/${objectPath}`

const pluralize = (count, noun) => `${noun}${count !== 1 ? "s" : ""}`

export {
  // parse-format
  normalizePhone,
  parsePhone,
  formatZipCode,
  normalizeCreditCardExpiration,
  formatSSN,
  parseSSN,
  parseNumber,
  parseNumericVerificationCode,
  parseBirthDate,
  formatDate,
  formatDateAsMonthDateYear,
  normalizeDateToYearMonthDay,
  normalizeDateToMonthDayYear,
  formatMonthDateYear,
  humanizeDate,
  formatCurrency,
  formatCurrencyCents,
  parseCurrency,
  parseCurrencyCents,
  formatCurrencyWithCents,
  formatCurrencyNoZeroCents,
  formatCurrencyWithOrWithoutCents,
  businessDaysFromDate,
  formatDateTime,
  formatUppercase,
  // cookies
  getCookie,
  setCookie,
  deleteCookie,
  // state-countries
  states,
  unitedStates,
  getStateNameFromAbbreviation,
  countries,
  countriesReordered,
  // other
  noop,
  checkIfDuplicateExists,
  creditCardType,
  DOMUtils,
  trackingAssist,
  useDimensions,
  useRemToPx,
  usePxToRem,
  isAdmin,
  getMobileOperatingSystem,
  isDateSupported,
  sleep,
  determineDefaultChecked,
  disableEnterSubmission,
  parseErrorMessages,
  toRGB,
  timezoneName,
  grabAllValues,
  getScript,
  canUseDOM,
  production,
  development,
  staging,
  fluteMatcher,
  reactiveRecordMatcher,
  spaceBetweenEach,
  triggerEventForProps,
  splice,
  resultAndRemainder,
  sortOn,
  sort,
  camelCase,
  toFirstName,
  parseCountry,
  toFirstNames,
  toSentence,
  endsWith,
  acceptedImageTypes,
  acceptedDocumentTypes,
  humanizeFileSize,
  noFilesExceedMaxAllowedFileSize,
  doesNotExceedMaxAllowedFileCount,
  filterFileListForAllowedFiles,
  without,
  pick,
  select,
  where,
  isEmptyObject,
  objToQueryString,
  queryStringToObj,
  scrollTo,
  toInitials,
  parseFloatWithoutNaNValues,
  maskedPhone,
  checkFormDataForEmptyStrings,
  focusOnElement,
  propertyTypes,
  toFinalFormErrors,
  parseGooglePlace,
  getTimeRemaining,
  hasValue,
  parseOperationErrors,
  selectErrors,
  formatResponse,
  makeRequest,
  setHoneyBadgerUser,
  facebookShareUrl,
  snakeToCamel,
  s3AssetUrl,
  s3EmailUrl,
  s3MediaKitUrl,
  s3MiscUrl,
  pluralize,
}
